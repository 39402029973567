<template>
  <!-- begin::ThirdPartiesPanel -->

  <div>
    <div class="d-flex justify-content-between thirdpartyItems">
      <!-- MC -->
      <div class="thirdpartyItem --w-3e-80" data-role="MC" :class="mobile ? '' : 'w-3e-80'">
        <span
          class="label label-md label-inline label-rounded px-1"
          :class="[
            MyRole === 'MC' ? 'label-primary' : '',
            CounterPartRole === 'MC' ? 'label-warning' : '',
            MyRole !== 'MC' && CounterPartRole !== 'MC' ? 'label-transparent' : '',
          ]"
        >
          <span
            class="svg-icon svg-icon-lg px-1"
            :class="
              MyRole === 'MC' || CounterPartRole === 'MC'
                ? 'svg-icon-white'
                : shipperName != ''
                ? 'svg-icon-opalean-gray-dark'
                : shipperName != ''
                ? 'svg-icon-opalean-gray-dark'
                : 'svg-icon-opalean-gray-light'
            "
          >
            <!--begin::Svg Icon -->
            <inline-svg src="media/opalean/svg/Shipper.svg" style="width: 24px !important"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="separator" v-if="!mobile"></span>
        <span
          class="d-block mb-1"
          :class="[
            MyRole === 'MC' || CounterPartRole === 'MC' ? 'font-weight-bolder' : 'font-weight-bold',
            MyRole === 'MC' ? 'text-primary' : '',
            CounterPartRole === 'MC' ? 'text-warning' : '',
            MyRole !== 'MC' && CounterPartRole !== 'MC' ? 'text-opalean-gray-dark' : '',
            mobile ? 'font-size-xs' : 'font-size-sm',
          ]"
          v-if="shipperName != ''"
          ><b-button
            size="sm"
            variant="link"
            :key="'MC'"
            @click="$emit('update', 'MC')"
            v-if="editMode && CounterPartRole !== 'MC' && MyRole !== 'MC' && OperationID == 0"
            class="text-primary p-0 mt-n1"
            ><i class="fas fa-lock-open icon-sm mr-2 text-primary"></i></b-button
          >{{ shipperName }}</span
        >
        <b-button
          size="sm"
          :class="[mobile ? 'font-size-xs' : 'font-size-sm']"
          variant="link"
          @click="$emit('update', 'MC')"
          v-else-if="editMode"
          class="mt-n1 mb-1 ml-4 text-opalean-gray-medium p-0"
          ><i class="fas fa-lock-open icon-sm mr-2"></i><translate>Add a shipper</translate></b-button
        >
      </div>
      <!-- MT -->
      <div class="thirdpartyItem w-3e-80" data-role="MT" v-if="!mobile">
        <span
          class="label label-md label-inline label-rounded px-1"
          :class="[
            MyRole === 'MT' ? 'label-primary' : '',
            CounterPartRole === 'MT' ? 'label-warning' : '',
            MyRole !== 'MT' && CounterPartRole !== 'MT' ? 'label-transparent' : '',
          ]"
        >
          <span
            class="svg-icon svg-icon-lg px-1"
            :class="
              MyRole === 'MT' || CounterPartRole === 'MT' ? 'svg-icon-white' : carrierName != '' ? 'svg-icon-opalean-gray-dark' : 'svg-icon-opalean-gray-light'
            "
          >
            <!--begin::Svg Icon -->
            <inline-svg src="media/opalean/svg/Carrier.svg" style="width: 24px !important"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="separator"></span>
        <span
          class="d-block mb-1"
          :class="[
            MyRole === 'MT' || CounterPartRole === 'MT' ? 'font-weight-bolder' : 'font-weight-bold',
            MyRole === 'MT' ? 'text-primary' : '',
            CounterPartRole === 'MT' ? 'text-warning' : '',
            MyRole !== 'MT' && CounterPartRole !== 'MT' ? 'text-opalean-gray-dark' : '',
            mobile ? 'font-size-xs' : 'font-size-sm',
          ]"
          v-if="carrierName != ''"
          ><b-button
            size="sm"
            variant="link"
            :key="'MT'"
            @click="$emit('update', 'MT')"
            v-if="editMode && CounterPartRole !== 'MT' && MyRole !== 'MT' && OperationID == 0"
            class="text-primary p-0 mt-n1"
            ><i class="fas fa-lock-open icon-sm mr-2 text-primary"></i></b-button
          >{{ carrierName }}</span
        >
        <b-button
          size="sm"
          :class="[mobile ? 'font-size-xs' : 'font-size-sm']"
          variant="link"
          @click="$emit('update', 'MT')"
          v-else-if="editMode"
          class="mt-n1 mb-1 ml-4 text-opalean-gray-medium p-0"
          ><i class="fas fa-lock-open icon-sm mr-2"></i><translate>Add a carrier</translate></b-button
        >
      </div>
      <!-- AD -->
      <div class="thirdpartyItem w-3e-80" data-role="AD" v-if="dockName != '' && typeof dockName != 'undefined' && !mobile">
        <span
          class="label label-md label-inline label-rounded px-1"
          :class="[
            MyRole === 'AD' ? 'label-primary' : '',
            CounterPartRole === 'AD' ? 'label-warning' : '',
            MyRole !== 'AD' && CounterPartRole !== 'AD' ? 'label-transparent' : '',
          ]"
        >
          <span
            class="svg-icon svg-icon-lg px-1"
            :class="
              MyRole === 'AD' || CounterPartRole === 'AD' ? 'svg-icon-white' : dockName != '' ? 'svg-icon-opalean-gray-dark' : 'svg-icon-opalean-gray-light'
            "
          >
            <!--begin::Svg Icon -->
            <inline-svg src="media/opalean/svg/Dock.svg" style="width: 24px !important"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="separator"></span>
        <span
          class="d-block mb-1"
          :class="[
            MyRole === 'AD' || CounterPartRole === 'AD' ? 'font-weight-bolder' : 'font-weight-bold',
            MyRole === 'AD' ? 'text-primary' : '',
            CounterPartRole === 'AD' ? 'text-warning' : '',
            MyRole !== 'AD' && CounterPartRole !== 'AD' ? 'text-opalean-gray-dark' : '',
            mobile ? 'font-size-xs' : 'font-size-sm',
          ]"
          v-if="dockName != ''"
          ><!-- <b-button
            size="sm"
            variant="link"
            :key="'AD'"
            @click="$emit('update', 'AD')"
            v-if="editMode && CounterPartRole !== 'AD' && MyRole !== 'AD' && OperationID == 0"
            class="text-primary p-0 mt-n1"
            ><i class="fas fa-lock-open icon-sm mr-2 text-primary"></i></b-button
          > -->{{ dockName }}</span
        >
        <!-- <b-button
          size="sm"
          :class="[mobile ? 'font-size-xs' : 'font-size-sm']"
          variant="link"
          @click="$emit('update', 'AD')"
          v-else-if="editMode"
          class="mt-n1 mb-1 ml-4 text-opalean-gray-medium p-0"
          ><i class="fas fa-lock-open icon-sm mr-2"></i><translate>Add a dock</translate></b-button
        > -->
      </div>
      <!-- MD -->
      <div class="thirdpartyItem w-3e-80" data-role="MD" v-if="!mobile">
        <span
          class="label label-md label-inline label-rounded px-1"
          :class="[
            MyRole === 'MD' ? 'label-primary' : '',
            CounterPartRole === 'MD' ? 'label-warning' : '',
            MyRole !== 'MD' && CounterPartRole !== 'MD' ? 'label-transparent' : '',
          ]"
        >
          <span
            class="svg-icon svg-icon-lg px-1"
            :class="
              MyRole === 'MD' || CounterPartRole === 'MD' ? 'svg-icon-white' : receiverName != '' ? 'svg-icon-opalean-gray-dark' : 'svg-icon-opalean-gray-light'
            "
          >
            <!--begin::Svg Icon -->
            <inline-svg src="media/opalean/svg/Receiver.svg" style="width: 24px !important"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="separator"></span>
        <span
          class="d-block mb-1"
          :class="[
            MyRole === 'MD' || CounterPartRole === 'MD' ? 'font-weight-bolder' : 'font-weight-bold',
            MyRole === 'MD' ? 'text-primary' : '',
            CounterPartRole === 'MD' ? 'text-warning' : '',
            MyRole !== 'MD' && CounterPartRole !== 'MD' ? 'text-opalean-gray-dark' : '',
            mobile ? 'font-size-xs' : 'font-size-sm',
          ]"
          v-if="receiverName != ''"
          ><b-button
            size="sm"
            variant="link"
            :key="'MD'"
            @click="$emit('update', 'MD')"
            v-if="editMode && CounterPartRole !== 'MD' && MyRole !== 'MD' && OperationID == 0"
            class="text-primary p-0 mt-n1"
            ><i class="fas fa-lock-open icon-sm mr-2 text-primary"></i></b-button
          >{{ receiverName }}</span
        >
        <b-button
          size="sm"
          :class="[mobile ? 'font-size-xs' : 'font-size-sm']"
          variant="link"
          @click="$emit('update', 'MD')"
          v-else-if="editMode"
          class="mt-n1 mb-1 ml-4 text-opalean-gray-medium p-0"
          ><i class="fas fa-lock-open icon-sm mr-2"></i><translate>Add a receiver</translate></b-button
        >
      </div>
      <!-- NN -->
      <div class="thirdpartyItem w-20" data-role="NN" v-if="!mobile">
        <span class="label label-md label-inline label-rounded px-1" :class="MyRole === 'NN' ? 'label-opalean-info' : 'label-transparent'">
          <span
            class="svg-icon svg-icon-lg px-1"
            :class="MyRole === 'NN' ? 'svg-icon-white' : notifiedName != '' ? 'svg-icon-opalean-info' : 'svg-icon-opalean-gray-light'"
          >
            <!--begin::Svg Icon -->
            <inline-svg src="media/opalean/svg/Notified.svg" style="width: 24px !important"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span
          class="d-block text-opalean-info mb-1"
          :class="[MyRole === 'NN' ? 'font-weight-bolder' : 'font-weight-bold', mobile ? 'font-size-xs' : 'font-size-sm']"
          v-if="notifiedName != ''"
          ><b-button
            size="sm"
            variant="link"
            :key="'NN'"
            @click="$emit('update', 'NN')"
            v-if="editMode && CounterPartRole !== 'NN' && MyRole !== 'NN'"
            class="text-opalean-info p-0 mt-n1"
            ><i class="fas fa-lock-open icon-sm mr-2 text-opalean-info"></i></b-button
          >{{ notifiedName }}</span
        >
        <b-button
          size="sm"
          :class="[mobile ? 'font-size-xs' : 'font-size-sm']"
          variant="link"
          @click="$emit('update', 'NN')"
          v-else-if="editMode"
          class="d-block mt-n1 mb-1 ml-4 text-opalean-gray-medium p-0"
          ><i class="fas fa-lock-open icon-sm mr-2"></i><translate>Add a notified</translate></b-button
        >
      </div>
    </div>

    <div
      class="d-flex justify-content-between thirdpartyItems"
      v-if="getUserCapability('CanSubcontract') && !mobile && (subShipperName != '' || subCarrierName != '' || subReceiverName != '')"
    >
      <!-- Removed SC -->
      <!-- <div class="thirdpartyItem w-3e-80 d-none" data-role="SC">
        <span class="label label-md label-inline label-rounded px-1 py-1" :class="MyRole === 'SC' ? 'label-primary' : 'label-transparent'">
          <span
            class="svg-icon svg-icon-lg px-1"
            :class="MyRole === 'SC' ? 'svg-icon-white' : subShipperName != '' ? 'svg-icon-opalean-gray-dark' : 'svg-icon-opalean-gray-light'"
          >
            <! --begin::Svg Icon -- >
            <inline-svg src="media/opalean/svg/SubShipper.svg" style="width: 24px !important"></inline-svg>
            <! --end::Svg Icon-- >
          </span>
        </span>
        <span class="separator"></span>
        <span
          class="d-block text-primary mb-1"
          :class="[MyRole === 'SC' ? 'font-weight-bolder' : 'font-weight-bold', mobile ? 'font-size-xs' : 'font-size-sm']"
          v-if="subShipperName != ''"
          ><b-button
            size="sm"
            variant="link"
            :key="'SC'"
            @click="$emit('update', 'SC')"
            v-if="editMode && CounterPartRole !== 'SC' && MyRole !== 'SC' && OperationID == 0"
            class="text-primary p-0 mt-n1"
            ><i class="fas fa-lock-open icon-sm mr-2 text-opalean-gray-dark --text-primary"></i></b-button
          >{{ subShipperName }}</span
        >
        <b-button
          size="sm"
          :class="[mobile ? 'font-size-xs' : 'font-size-sm']"
          variant="link"
          @click="$emit('update', 'SC')"
          v-else-if="editMode"
          class="mt-n1 mb-1 ml-4 text-opalean-gray-medium p-0"
          ><i class="fas fa-lock-open icon-sm mr-2"></i><translate>Add a sub-shipper</translate></b-button
        >
      </div> -->
      <!-- Spacer SC -->
      <div class="thirdpartyItem w-3e-80" v-if="!mobile"></div>
      <!-- ST -->
      <div class="thirdpartyItem w-3e-80" data-role="ST" v-if="!mobile">
        <span
          class="label label-md label-inline label-rounded px-1 py-1"
          :class="[
            MyRole === 'ST' ? 'label-primary' : '',
            CounterPartRole === 'ST' ? 'label-warning' : '',
            MyRole !== 'ST' && CounterPartRole !== 'ST' ? 'label-transparent' : '',
          ]"
        >
          <span
            class="svg-icon svg-icon-lg px-1"
            :class="
              MyRole === 'ST' || CounterPartRole === 'ST'
                ? 'svg-icon-white'
                : subCarrierName != ''
                ? 'svg-icon-opalean-gray-dark'
                : 'svg-icon-opalean-gray-light'
            "
          >
            <!--begin::Svg Icon -->
            <inline-svg src="media/opalean/svg/SubCarrier.svg" style="width: 24px !important"></inline-svg>
            <!--end::Svg Icon-->
          </span>
        </span>
        <span class="separator"></span>
        <span
          class="d-block mb-1"
          :class="[
            MyRole === 'ST' || CounterPartRole === 'ST' ? 'font-weight-bolder' : 'font-weight-bold',
            MyRole === 'ST' ? 'text-primary' : '',
            CounterPartRole === 'ST' ? 'text-warning' : '',
            MyRole !== 'ST' && CounterPartRole !== 'ST' ? 'text-opalean-gray-dark' : '',
            mobile ? 'font-size-xs' : 'font-size-sm',
          ]"
          v-if="subCarrierName != ''"
          ><b-button
            size="sm"
            variant="link"
            :key="'ST'"
            @click="$emit('update', 'ST')"
            v-if="editMode && CounterPartRole !== 'ST' && MyRole !== 'ST' && OperationID == 0"
            class="text-primary p-0 mt-n1"
            ><i class="fas fa-lock-open icon-sm mr-2 text-opalean-gray-dark --text-primary"></i></b-button
          >{{ subCarrierName }}</span
        >
        <b-button
          size="sm"
          :class="[mobile ? 'font-size-xs' : 'font-size-sm']"
          variant="link"
          @click="$emit('update', 'ST')"
          v-else-if="editMode"
          class="mt-n1 mb-1 ml-4 text-opalean-gray-medium p-0"
          ><i class="fas fa-lock-open icon-sm mr-2"></i><translate>Add a sub-carrier</translate></b-button
        >
      </div>
      <!-- Spacer AD -->
      <div class="thirdpartyItem w-3e-80" v-if="dockName != '' && typeof dockName != 'undefined' && !mobile"></div>
      <!-- Removed SD -->
      <!-- <div class="thirdpartyItem w-3e-80 d-none" data-role="SD">
        <span class="label label-md label-inline label-rounded px-1 py-1" :class="MyRole === 'SD' ? 'label-primary' : 'label-transparent'">
          <span
            class="svg-icon svg-icon-lg px-1"
            :class="MyRole === 'SD' ? 'svg-icon-white' : subReceiverName != '' ? 'svg-icon-opalean-gray-dark' : 'svg-icon-opalean-gray-light'"
          >
            <! --begin::Svg Icon -- >
            <inline-svg src="media/opalean/svg/SubReceiver.svg" style="width: 24px !important"></inline-svg>
            <! --end::Svg Icon-- >
          </span>
        </span>
        <! -- <span class="separator"></span> -- >
        <span
          class="d-block text-primary mb-1"
          :class="[MyRole === 'SD' ? 'font-weight-bolder' : 'font-weight-bold', mobile ? 'font-size-xs' : 'font-size-sm']"
          v-if="subReceiverName != ''"
          ><b-button
            size="sm"
            variant="link"
            :key="'SD'"
            @click="$emit('update', 'SD')"
            v-if="editMode && CounterPartRole !== 'SD' && MyRole !== 'SD' && OperationID == 0"
            class="text-primary p-0 mt-n1"
            ><i class="fas fa-lock-open icon-sm mr-2 text-opalean-gray-dark --text-primary"></i></b-button
          >{{ subReceiverName }}</span
        >
        <b-button
          size="sm"
          :class="[mobile ? 'font-size-xs' : 'font-size-sm']"
          variant="link"
          @click="$emit('update', 'SD')"
          v-else-if="editMode"
          class="mt-n1 mb-1 ml-4 text-opalean-gray-medium p-0"
          ><i class="fas fa-lock-open icon-sm mr-2"></i><translate>Add a sub-receiver</translate></b-button
        >
      </div> -->
      <!-- Spacer SD -->
      <div class="thirdpartyItem w-3e-80" v-if="!mobile"></div>
      <!-- Spacer NN -->
      <div class="thirdpartyItem w-20" v-if="!mobile"></div>
    </div>
  </div>
  <!-- end::ThirdPartiesPanel -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ThirdPartiesPanel",
  props: {
    MyRole: String,
    CounterPartRole: String,
    // Main
    shipperName: String,
    carrierName: String,
    receiverName: String,
    notifiedName: String,
    dockName: String,
    // Sub
    subShipperName: String,
    subCarrierName: String,
    subReceiverName: String,
    // Edit mode
    editMode: {
      type: Boolean,
      default: false,
    },
    OperationID: Number,
    // Mobile
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getUserCapability"]),
  },
};
</script>
